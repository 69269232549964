<template>
  <div>
    <h4 class="title has-text-centered is-4">Logs by date</h4>

    <b-loading :is-full-page="false" v-model="loadingLogs"></b-loading>

    <div class="columns" style="font-size: 12px;">
      <div class="column is-2">
        <h6 class="title is-6">Available</h6>
        <ul>
          <li v-for="logfile in logfiles" :key="logfile"><a @click="loadLogsByDate(logfile)">{{ logfile }}</a></li>
        </ul>
      </div>
      <div class="column is-10" v-if="selectedLog">
        <h6 class="title is-6">{{ selectedLog }}</h6>
        <b-table
          :data="logs"
          ref="table"
          detailed
          detail-key="id"
          detail-transition="fade"
          style="max-width: 100%;"
          :row-class="(row) => row.request === 'POST login' && 'login-log'"
        >

          <b-table-column field="datetime" label="Time" v-slot="props" width="160">
            {{ props.row.datetime }}
          </b-table-column>

          <b-table-column field="code" label="Code" v-slot="props" width="50">
            <b-tag v-if="props.row.code > 0" :type="classByCode(props.row.code)" style="font-weight: bold;">{{ props.row.code }}</b-tag>
          </b-table-column>

          <b-table-column field="request" label="Request" v-slot="props">
            {{ props.row.request }}
          </b-table-column>

          <template #detail="props">
            <div>
              <div v-if="props.row.postData">
                <strong>POST BODY:</strong>
                <img v-if="props.row.postData.avatar" :src="props.row.postData.avatar" />
                <pre v-else style="word-wrap: anywhere;">{{ JSON.stringify(props.row.postData, undefined, 4) }}</pre>
              </div>
              <strong>RESPONSE:</strong>
              <pre style="word-wrap: anywhere;">{{ JSON.stringify(props.row.response, undefined, 4) }}</pre>
            </div>
          </template>
        </b-table>
      </div>
    </div>

  </div>
</template>

<script>
import { User } from '@/services/api';

export default {
  name: 'user-logs',
  props: {
    user: Object,
  },
  data() {
    return {
      loadingLogs: false,
      logfiles: [],
      logs: [],
      selectedLog: '',
    };
  },
  methods: {
    classByCode(code) {
      if (code >= 500) {
        return 'is-danger';
      }

      if (code >= 400) {
        return 'is-warning';
      }

      return 'is-success';
    },
    loadLogsByDate(date) {
      this.selectedLog = date;
      this.loadingLogs = true;
      User.getLogs(this.user.id, date)
        .then((data) => {
          this.logs = data;
        })
        .finally(() => {
          this.loadingLogs = false;
        });
    },
  },
  mounted() {
    User.getLogFiles(this.user.id)
      .then((data) => {
        this.logfiles = data;
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: anywhere;
}

.login-log {
  background: #daebfd;
}
</style>
