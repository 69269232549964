<template>
  <div>
    <h4 class="title has-text-centered is-4">Participation in Feats</h4>

    <feat-list :feats="feats"></feat-list>
  </div>
</template>

<script>
import FeatList from '../Feats/FeatList.vue';
import { User } from '@/services/api';

export default {
  name: 'earnings',
  components: { FeatList },
  props: {
    user: Object,
  },
  data() {
    return {
      feats: [],
    };
  },
  mounted() {
    User.getFeats(this.user.id)
      .then((data) => {
        this.feats = data;
      });
  },
};
</script>
