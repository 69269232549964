<template>
  <div>
    <h4 class="title has-text-centered is-4">Profile</h4>

    <div class="columns">
      <div class="column is-6">
        <strong>Testing Token:</strong><br />
        <code style="word-wrap: anywhere;">{{ user.authToken }}</code>
        <br /><br />
        <form @submit="handleSubmit">
          <b-field label="Name" custom-class="is-small">
            <b-input v-model="formData.name" size="is-small"></b-input>
          </b-field>

          <b-field label="Location" custom-class="is-small">
            <b-input v-model="formData.location_id" size="is-small"></b-input>
          </b-field>

          <hr />

          <div class="columns">
            <div class="column">
              <b-field>
                <b-checkbox v-model="formData.featured" :true-value="1" :false-value="0" type="is-warning">Featured</b-checkbox>
              </b-field>
            </div>
            <div class="column">

              <b-field>
                <b-checkbox v-model="formData.patreon" :true-value="1" :false-value="0" type="is-danger">Patreon</b-checkbox>
              </b-field>

              <b-field label="Patreon Email" custom-class="is-small">
                <b-input v-model="formData.patreon_email" size="is-small"></b-input>
              </b-field>

              <b-field label="Patreon Amount (cents)" custom-class="is-small">
                <b-input v-model="formData.patreon_amount" size="is-small"></b-input>
              </b-field>
            </div>
          </div>

          <b-button type="is-success" native-type="submit" icon-left="check">Update</b-button>
        </form>
      </div>

      <div class="column is-6" v-if="user.relatedLocations.length > 0">
        <strong>Nearby Locations:</strong>

        <ul>
          <li v-for="location in user.relatedLocations" :key="location.id">
            <a @click="switchLocation(location.id)">{{ location.id }} / {{ location.label }}</a>
          </li>
        </ul>
      </div>
    </div>

    <h5 class="title is-5">Stats</h5>
    <pre>{{ JSON.stringify(userStats, undefined, 4) }}</pre>
  </div>
</template>

<script>
import { User } from '@/services/api';

export default {
  name: 'user-edit-form',
  props: {
    user: Object,
  },
  watch: {
    user: {
      immediate: true,
      handler(val) {
        this.formData = {
          ...val,
        };
      },
    },
  },
  computed: {
    userStats() {
      const statsCopy = [
        ...this.user.stats,
      ];
      const statsObject = {};

      statsCopy.sort((a, b) => (a.key > b.key ? 1 : -1));

      statsCopy.forEach((stat) => {
        statsObject[stat.key] = stat.value;
      });

      return statsObject;
    },
  },
  data() {
    return {
      errors: [],
      formData: {
        name: null,
      },
      loadingForm: false,
      sent: false,
    };
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();

      this.$buefy.dialog.confirm({
        title: 'Updating user',
        message: 'Are you sure you want to <b>update</b> this user? This action cannot be undone and might have serious consequences!',
        confirmText: 'Update user',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => {
          this.errors = [];
          this.loadingForm = true;

          User.updateUser(this.user.id, {
            name: this.formData.name,
            location_id: this.formData.location_id,
            featured: this.formData.featured,
            patreon: this.formData.patreon,
            patreon_email: this.formData.patreon_email,
            patreon_amount: this.formData.patreon_amount,
          })
            .then(() => {
              this.sent = true;
              this.refreshUser();
            })
            .catch((error) => {
              const responseError = error.response.data;

              // Validation errors
              if (responseError.errors) {
                Object.keys(responseError.errors).forEach((key) => {
                  this.errors.push(responseError.errors[key][0]);
                });

                // General error
              } else {
                this.errors.push('Sorry :( Something went wrong... please try again later');
              }
            })
            .finally(() => {
              this.loadingForm = false;
            });
        },
      });
    },
    refreshUser() {
      this.$store.dispatch('user/loadUser', this.user.id);
    },
    switchLocation(locationId) {
      this.formData.location_id = locationId;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
