<template>
  <div>
    <div>
      <h4 class="title has-text-centered is-4">Furniture</h4>

      <ul>
        <li v-for="item in furniture" :key="item.id">
          <router-link :to="`/furniture/${item.id}`">
            {{ item.stack}} x {{ item.id }} - {{ item.name }}
            ({{ item.monetti_price || item.currants_price }} {{ item.monetti_price ? 'monetti' : 'currants'}})
          </router-link>
        </li>
      </ul>
    </div>
    <div class="mt-6">
      <h4 class="title has-text-centered is-4">Buy Transactions</h4>

      <ul>
        <li v-for="furnitureSale in buy_furniture_sales" :key="furnitureSale.id">
          {{ furnitureSale.created_at | dateStandard }} - {{ furnitureSale.furniture_id }}
          ({{ furnitureSale.monetti_price || furnitureSale.currants_price }} {{ furnitureSale.monetti_price ? 'monetti' : 'currants'}})
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { User } from '@/services/api';

export default {
  name: 'user-furniture',
  props: {
    user: Object,
  },
  data() {
    return {
      furniture: null,
      buy_furniture_sales: null,
    };
  },
  mounted() {
    User.getFurniture(this.user.id)
      .then((data) => {
        this.furniture = data.furniture;
        this.buy_furniture_sales = data.buy_furniture_sales;
      });
  },
};
</script>
