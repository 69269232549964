<template>
  <div>
    <b-table
      :data="feats"
      :striped="true"
      :hoverable="true"
      class="mt-4 clickable-table user-table"
      @click="goToFeat"
    >
      <template v-slot:empty>
        <div class="has-text-centered">
          <h3>No feats yet...</h3>
        </div>
      </template>

      <b-table-column field="epic" label="Epic" v-slot="props">
        <p>
          <strong>{{ epic ? epic.name : props.row.epic.name }}</strong>
        </p>
      </b-table-column>

      <b-table-column field="name" label="Name" v-slot="props">
        <p>
          <strong>{{ props.row.name }}</strong>
        </p>
      </b-table-column>

      <b-table-column field="action" label="Action" v-slot="props">
        <p>
          <strong>{{ props.row.action }}</strong>
          <br />
          {{ props.row.goal_minimum }} | {{ props.row.goal_bonus }} | {{ props.row.goal_supermega }}
        </p>
      </b-table-column>

      <b-table-column field="date_start" label="Start" v-slot="props">
        <p>
          {{ props.row.date_start | dateStandard }}
        </p>
      </b-table-column>

      <b-table-column field="date_start" label="End" v-slot="props">
        <p>
          {{ props.row.date_end | dateStandard }}
        </p>
      </b-table-column>

      <b-table-column :visible="shouldShowUserData" field="date_joined" label="Joined" v-slot="props">
        <p>
          {{ props.row.pivot.date_joined | dateStandard }}
        </p>
      </b-table-column>

      <b-table-column :visible="shouldShowUserData" field="contribution" label="Contribution" v-slot="props">
        <p>
          {{ props.row.pivot.contribution }}
        </p>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'feat-list',
  props: {
    epic: Object,
    feats: Array,
  },
  computed: {
    shouldShowUserData() {
      return Boolean(this.feats && this.feats[0] && this.feats[0].pivot);
    },
  },
  methods: {
    goToFeat(row) {
      this.$router.push({ path: `/feats/${row.id}` });
    },
  },
};

</script>

<style>
.user-table {
  font-size: 12px;
}
</style>
