<template>
  <section class="section">
    <b-loading :is-full-page="false" v-model="loadingUser"></b-loading>

    <div v-if="selectedUser">
      <div class="columns">
        <div class="column is-9">
          <h4 class="title is-4">{{ selectedUser.id }} / {{ selectedUser.name }} / {{ selectedUser.email }}</h4>
        </div>
        <div class="column is-3 has-text-right">
          <b-icon icon="star" :type="selectedUser.featured ? 'is-warning' : 'is-light'" size="is-large"></b-icon>
          <b-icon icon="money-bill-wave" :type="selectedUser.patreon ? 'is-danger' : 'is-light'" size="is-large"></b-icon>
          <b-icon icon="dove" :type="selectedUser.early_bird ? 'is-success' : 'is-light'" size="is-large"></b-icon>
          <b-icon icon="envelope-open" :type="selectedUser.subscribed ? 'is-success' : 'is-light'" size="is-large"></b-icon>
        </div>
      </div>

      <div class="columns">
        <div class="column is-8" v-if="selectedUser.last_known_location">
          <h5 class="title is-6">{{ selectedUser.last_known_location.id }} / {{ selectedUser.last_known_location.label }}</h5>

          <img style="width: 100%;" :src="locationImageUrl(selectedUser.last_known_location)" />
        </div>

        <div class="column is-4">
          <ul style="font-size: 12px;">
            <li><strong>Registered:</strong> {{ selectedUser.created_at | dateStandard }}</li>
            <li><strong>Last Login:</strong> {{ selectedUser.last_login | dateStandard }}</li>
          </ul>

          <hr />

          <div class="columns">
            <div class="column">
              <player-avatar :user="selectedUser" size="medium" />
            </div>
            <div class="column stat-list">
              <div><b-icon icon="user-cog" size="is-small"></b-icon> {{ selectedUser.level }} Level</div>
              <div><b-icon icon="cog" size="is-small"></b-icon> {{ selectedUser.experience }}</div>
              <div><img :src="iconImageUrl('currants.png')" class="user-stat-icon" /> {{ selectedUser.currants }}</div>
              <div><img :src="iconImageUrl('imagination.png')" class="user-stat-icon" /> {{ selectedUser.imagination }}</div>
              <div><img :src="iconImageUrl('energy.png')" class="user-stat-icon" /> {{ selectedUser.energy }}</div>
              <div><img :src="iconImageUrl('mood.png')" class="user-stat-icon" /> {{ selectedUser.mood }}</div>
            </div>
          </div>
        </div>
      </div>

      <b-tabs type="is-boxed" vertical destroy-on-hide>
        <b-tab-item label="Profile">
          <user-edit-form :user="selectedUser"></user-edit-form>
        </b-tab-item>
        <b-tab-item label="Inventory">
          <inventory :user="selectedUser"></inventory>
        </b-tab-item>
        <b-tab-item label="Additional">
          <additional-data :user="selectedUser"></additional-data>
        </b-tab-item>
        <b-tab-item label="Home Streets">
          <user-homestreets :user="selectedUser"></user-homestreets>
        </b-tab-item>
        <b-tab-item label="Furniture">
          <user-furniture :user="selectedUser"></user-furniture>
        </b-tab-item>
        <b-tab-item label="Feats">
          <user-feats :user="selectedUser"></user-feats>
        </b-tab-item>
        <b-tab-item label="Patreon">
          <user-patreon-logs :user="selectedUser"></user-patreon-logs>
        </b-tab-item>
        <b-tab-item label="Earnings">
          <user-earnings :user="selectedUser"></user-earnings>
        </b-tab-item>
        <b-tab-item label="Logs">
          <user-logs :user="selectedUser"></user-logs>
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { getEncyclopediaImageUrl, getGeneralImageUrl } from '@/services/encyclopedia';
import AdditionalData from '@/components/Users/AdditionalData.vue';
import Inventory from '@/components/Users/Inventory.vue';
import UserEditForm from '@/components/Users/EditForm.vue';
import UserLogs from '@/components/Users/Logs.vue';
import PlayerAvatar from '@/components/Users/Avatar.vue';
import UserHomestreets from '@/components/Users/Homestreets.vue';
import UserFurniture from '@/components/Users/Furniture.vue';
import UserFeats from '../../components/Users/Feats.vue';
import UserPatreonLogs from '../../components/Users/PatreonLogs.vue';
import UserEarnings from '../../components/Users/Earnings.vue';

export default {
  name: 'UserDetails',
  components: {
    UserEarnings,
    UserPatreonLogs,
    UserFeats,
    UserFurniture,
    UserHomestreets,
    PlayerAvatar,
    UserLogs,
    UserEditForm,
    AdditionalData,
    Inventory,
  },
  props: {
    user: String,
  },
  computed: {
    ...mapState('user', ['loadingUser', 'selectedUser']),
  },
  mounted() {
    this.loadUser(this.user);
  },
  beforeRouteUpdate(to, from, next) {
    this.loadUser(to.params.user);
    next();
  },
  methods: {
    loadUser(userId) {
      this.$store.dispatch('user/loadUser', userId);
    },
    locationImageUrl(location) {
      return getEncyclopediaImageUrl('locations', location.id);
    },
    iconImageUrl(file) {
      return getGeneralImageUrl(file);
    },
  },
};
</script>

<style lang="scss">
.stat-list > div {
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  .user-stat-icon {
    width: 18px;
    height: 18px;
    margin-right: 3px;
  }
}
.b-tabs.is-vertical {
  flex-wrap: nowrap;
}
</style>
