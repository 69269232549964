<template>
  <div :class="`${inlineThumb ? 'image-inline' : ''} ${size} item-image-container`">
    <b-tooltip
      :label="label"
      type="is-dark"
      position="is-bottom"
    >
      <img :src="imageSource"/>
    </b-tooltip>

    <div v-if="stringBadge" class="badge counter-label">{{ stringBadge }}</div>
    <b-icon v-if="iconBadge" pack="fas" :icon="iconBadge" size="is-small" class="badge" type="is-danger"></b-icon>
  </div>
</template>

<script>
import { getEncyclopediaImageUrl } from '../../services/encyclopedia';

export default {
  name: 'item-image',
  props: {
    thumb: Boolean,
    inlineThumb: Boolean,
    type: String,
    id: String,
    label: String,
    badge: String,
    size: String,
  },
  watch: {
    badge: {
      immediate: true,
      handler(val) {
        if (val) {
          const badge = val.toString();
          if (badge.includes('icon/')) {
            // eslint-disable-next-line prefer-destructuring
            this.iconBadge = badge.split('/')[1];
          } else {
            this.stringBadge = badge;
          }
        }
      },
    },
  },
  data() {
    return {
      stringBadge: null,
      iconBadge: null,
    };
  },
  computed: {
    imageSource() {
      return getEncyclopediaImageUrl(this.type, this.id, this.inlineThumb || this.thumb);
    },
  },
};
</script>

<style scoped lang="scss">
  .item-image-container {
    height: 60px;
    width: 60px;
    margin: 4px;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 15px;
    text-align: center;
    vertical-align: middle;
    position: relative;

    &.small {
      height: 60px;
      width: 60px;
    }

    &.medium {
      height: 90px;
      width: 90px;
    }

    .badge {
      position: absolute;
      bottom: 0;
      right: 0;

      &.counter-label {
        background-color: #ddd;
        padding: 4px;
        border-radius: 30px;
        font-size: 10px;
        font-weight: bold;
      }
    }
  }

  .image-inline {
    display: inline-block;

    > img {
      width: 16px;
      height: 16px;
      margin-left: 9px;
      margin-right: 3px;
    }
  }
</style>
