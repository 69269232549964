<template>
  <div>
    <h4 class="title has-text-centered is-4">Patreon Sync Logs</h4>
    <sync-logs-list :logs="patreonActions" :user="user" :loading="false"></sync-logs-list>
  </div>
</template>

<script>
import SyncLogsList from '../Patreon/SyncLogsList.vue';
import { User } from '@/services/api';

export default {
  name: 'earnings',
  components: { SyncLogsList },
  props: {
    user: Object,
  },
  data() {
    return {
      patreonActions: [],
    };
  },
  mounted() {
    User.getPatreonActions(this.user.id)
      .then((data) => {
        this.patreonActions = data;
      });
  },
};
</script>
