<template>
  <div>
    <h4 class="title has-text-centered is-4">Earnings</h4>

    <earnings-list :earnings="earnTransactions" :user="user" :loading="false"></earnings-list>
  </div>
</template>

<script>
import EarningsList from '../Transactions/EarningsList.vue';
import { User } from '@/services/api';

export default {
  name: 'earnings',
  components: { EarningsList },
  props: {
    user: Object,
  },
  data() {
    return {
      earnTransactions: [],
    };
  },
  mounted() {
    User.getEarnTransactions(this.user.id)
      .then((data) => {
        this.earnTransactions = data;
      });
  },
};
</script>
