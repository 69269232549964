<template>
  <div>
    <h4 class="title has-text-centered is-4">Home Streets</h4>

    <b-loading :is-full-page="false" v-model="loading"></b-loading>

    <div v-for="homestreet in homestreets" :key="homestreet.id" class="mb-6">
      <b-button
        type="is-danger"
        class="is-pulled-right"
        size="is-small"
        @click="deleteHomestreet(homestreet.id)"
      >
        <b-icon icon="trash"></b-icon>
      </b-button>

      <h5 class="title is-5">Home Street #{{ homestreet.id }} <span v-if="homestreet.default" class="has-text-info">DEFAULT</span></h5>

      <pre>{{ JSON.stringify(homestreet, undefined, 4) }}</pre>
    </div>

  </div>
</template>

<script>
import { User } from '@/services/api';

export default {
  name: 'user-homestreets',
  props: {
    user: Object,
  },
  data() {
    return {
      loading: false,
      homestreets: [],
    };
  },
  methods: {
    getHomeStreets() {
      User.getHomeStreets(this.user.id)
        .then((data) => {
          this.homestreets = data;
        });
    },
    deleteHomestreet(id) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Homestreet',
        message: 'Are you <b>ABSOLUTELY</b> sure you want to <b>delete</b> this Homestreet? This action cannot be undone and can cause unpredicted results in game... who knows...?',
        confirmText: 'Delete Homestreet',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.loading = true;
          User.deleteHomestreet(this.user.id, id)
            .then(() => {
              this.getHomeStreets();
            })
            .finally(() => {
              this.loading = false;
            });
        },
      });
    },
  },
  mounted() {
    this.getHomeStreets();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;
}
</style>
