<template>
  <div :class="`avatar-container avatar-container-${size}`">
    <img :src="imageSource" :class="`avatar avatar-${size}`" />
  </div>
</template>

<script>
import { getCDNImageAvatar } from '../../services/cdn';

export default {
  name: 'player-avatar',
  props: {
    user: Object,
    size: String,
  },
  computed: {
    imageSource() {
      return `${getCDNImageAvatar(this.user.avatarImage)}?v=${new Date(this.user.last_login).getTime()}`;
    },
  },
};
</script>

<style scoped lang="scss">
img.avatar {
  &-head {
    width: 50px;
    object-fit: cover;
    object-position: 100% 0;
    max-height: 49px;
    margin-right: 5px;
    margin-bottom: -14px;
    margin-top: -12px;
  }

  &-full {
    max-width: 140px;
  }

  &-medium {
    max-width: 80px;
  }

  &-circle {
    border: 1px solid #dbdbdb;
    width: 50px;
    object-fit: cover;
    object-position: 100% 20%;
    max-height: 49px;
    border-radius: 50%;
  }
}

.avatar-container {
  &-small {
    height: 50px;
  }
}

</style>
