<template>
  <div>
    <h5 class="title is-5 mt-5">Skills</h5>
    <ItemImage
      v-for="skill in skills"
      :key="skill.id"
      type="skills"
      :id="skill.id"
      inline-thumb
      :label="`${skill.name}${skill.learned ? '' : ' - ' + skill.ends}`"
      :badge="skill.learning ? 'icon/clock' : null"
    />

    <h5 class="title is-5 mt-5">Upgrades</h5>
    <ItemImage
      v-for="upgrade in upgrades"
      :key="upgrade.id"
      type="upgrades"
      :id="upgrade.id"
      inline-thumb
      :label="upgrade.name"
      size="medium"
    />

    <h5 class="title is-5 mt-5">Achievements</h5>
    <ItemImage
      v-for="achievement in achievements"
      :key="achievement.id"
      type="achievements"
      :id="achievement.id"
      inline-thumb
      :label="achievement.name"
      size="medium"
    />

  </div>
</template>

<script>
import ItemImage from '@/components/Encyclopedia/ItemImage.vue';
import { User } from '@/services/api';

export default {
  name: 'additional-data',
  components: { ItemImage },
  props: {
    user: Object,
  },
  data() {
    return {
      achievements: null,
      skills: null,
      upgrades: null,
    };
  },
  mounted() {
    User.getAdditionalData(this.user.id)
      .then((data) => {
        this.achievements = data.achievements;
        this.skills = data.skills;
        this.upgrades = data.upgrades;
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
