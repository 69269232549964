<template>
  <div>

    <h5 class="title is-5">Inventory</h5>
    <div
      v-for="(slotItems, index) in inventory"
      :key="index"
    >
      <strong><small>{{ index === '0' ? 'MAIN' : 'BAG on slot #' + (index -1) }}</small></strong>
      <br />
      <ItemImage
        v-for="item in slotItems"
        :key="item.slot_id"
        type="items"
        :id="item.id"
        inline-thumb
        :label="`${item.slot_id}: ${item.stack} x ${item.name}`"
        :badge="item.stack.toString()"
      />

    </div>

  </div>
</template>

<script>
import ItemImage from '@/components/Encyclopedia/ItemImage.vue';
import { User } from '@/services/api';

export default {
  name: 'user-profile',
  components: { ItemImage },
  props: {
    user: Object,
  },
  computed: {
    inventory() {
      if (!this.user || this.items.length === 0) {
        return {};
      }

      const items = {};

      this.items.forEach((item) => {
        const parentSlotId = parseInt(item.parent_slot_id, 10) + 1;

        if (!items[parentSlotId]) {
          items[parentSlotId] = [];
        }

        items[parentSlotId].push(item);
      });

      return items;
    },
  },
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    User.getInventory(this.user.id)
      .then((data) => {
        this.items = data;
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
